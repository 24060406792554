import { apiRequest, DataReturnType, VehicleFavoriteCreateDTO } from "@alba-cars/common-modules";

type ToggleFavoriteResponse = {
  added: number;
  removed: number;
};

type ToggleFavoriteRequest = {
  payload: Omit<VehicleFavoriteCreateDTO, "validate" | "toPlain">;
  token: string;
};

export const toggleFavorite = async (input: ToggleFavoriteRequest) => {
  const { payload, token } = input;
  const endpoint = `/favorites/toggle`;

  return apiRequest<DataReturnType<ToggleFavoriteResponse>>(endpoint, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
