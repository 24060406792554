import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { z } from "zod";

export const authSchema = z.discriminatedUnion("authType", [
  z.object({
    authType: z.literal("login"),
    phone: z
      .string()
      .min(8, "Phone number is required")
      .refine((value) => {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        if (!phoneNumber) return false;
        return isValidPhoneNumber(value);
      }, "Please enter a valid phone number"),
    code: z.string().length(6, "Please enter the 6-digit code sent to your phone").optional(),
    savedFavoriteVehicles: z.array(z.string().uuid("Invalid vehicle ID format")).optional(),
  }),
  z.object({
    authType: z.literal("register"),
    name: z.string().min(2, "Full name is required"),
    email: z.string().email("Please enter a valid email").optional().nullable(),
    phone: z
      .string()
      .min(8, "Phone number is required")
      .refine((value) => {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        if (!phoneNumber) return false;
        return isValidPhoneNumber(value);
      }, "Please enter a valid phone number"),
    code: z.string().length(6, "Please enter the 6-digit code sent to your phone"),
    savedFavoriteVehicles: z.array(z.string().uuid("Invalid vehicle ID format")).optional(),
  }),
]);

export type AuthFormValues = z.infer<typeof authSchema>;
