"use client";

import { timeAsMilliseconds } from "@alba-cars/common-modules";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";

import { getAuthenticatedUserFavoriteIds } from "@/lib/api/favorites/get-user-favorites";
import { toggleFavorite as toggleFavoriteApi } from "@/lib/api/favorites/toggle-favorite";
import { FAVORITES_KEY } from "@/lib/utils";
import { useAuth } from "./AuthContext";

interface FavoritesContextType {
  favorites: Set<string>;
  toggleFavorite: (id: string) => Promise<void>;
  isFavorite: (id: string) => boolean;
  clearFavorites: () => void;
}

const FavoritesContext = createContext<FavoritesContextType | undefined>(undefined);

export const FavoritesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const { isAuthenticated, user, isAuthLoading } = useAuth();

  const token = user?.accessToken ?? "";

  const toggleFavorite = async (id: string) => {
    const previousFavorites = new Set(favorites);

    // Optimistically update UI
    const newFavorites = new Set(favorites);
    if (newFavorites.has(id)) {
      newFavorites.delete(id);
    } else {
      newFavorites.add(id);
    }
    setFavorites(newFavorites);

    try {
      if (isAuthLoading) {
        // Wait for authentication to finish loading before proceeding
        await new Promise((resolve) => {
          const checkAuth = setInterval(() => {
            if (!isAuthLoading) {
              clearInterval(checkAuth);
              resolve(null);
            }
          }, 100); // Check every 100ms
        });
      }

      if (isAuthenticated) {
        await toggleFavoriteApi({
          payload: {
            userId: user?.id ?? "",
            vehicleIds: id,
          },
          token,
        });
      } else {
        // Update localStorage for non-authenticated users
        localStorage.setItem(FAVORITES_KEY, JSON.stringify(Array.from(newFavorites)));
      }
    } catch (error) {
      // Rollback on error
      console.error("Failed to toggle favorite:", error);
      setFavorites(previousFavorites);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["auth-favorites", { token: user?.accessToken ?? "" }],
    queryFn: () => getAuthenticatedUserFavoriteIds(user?.accessToken ?? ""),
    enabled: isAuthenticated,
    refetchInterval: timeAsMilliseconds({ hours: 2 }),
    staleTime: timeAsMilliseconds({ hours: 2 }),
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useEffect(() => {
    if (typeof window === "undefined") return;

    const storedFavorites = localStorage.getItem(FAVORITES_KEY);

    if (storedFavorites) {
      try {
        const parsedFavorites = JSON.parse(storedFavorites);
        setFavorites(new Set(parsedFavorites));
      } catch (error) {
        console.error("Error parsing favorites from localStorage:", error);
        localStorage.removeItem(FAVORITES_KEY);
      }
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    if (isAuthenticated) {
      if (isLoading) {
        // Don't update favorites while loading to prevent flicker
        return;
      }
      if (data) {
        const favoritesArray = data?.data ?? [];
        const favorites = new Set(favoritesArray);
        localStorage.setItem(FAVORITES_KEY, JSON.stringify(favoritesArray));
        setFavorites(favorites);
      } else {
        setFavorites(new Set());
      }
    } else {
      const storedFavorites = localStorage.getItem(FAVORITES_KEY);
      if (storedFavorites) {
        setFavorites(new Set(JSON.parse(storedFavorites)));
      }
    }
  }, [isAuthenticated, data, isLoading]);

  const clearFavorites = () => {
    setFavorites(new Set());
    localStorage.removeItem(FAVORITES_KEY);
  };

  const isFavorite = (id: string) => favorites.has(id);

  return (
    <FavoritesContext.Provider value={{ favorites, toggleFavorite, isFavorite, clearFavorites }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (context === undefined) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }
  return context;
};
