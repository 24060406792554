"use client";

import { AuthResponseDTO, DataReturnType, ErrorType, LoginDTO, RegisterDTO } from "@alba-cars/common-modules";

interface RegisterDTOWithOptionalPassword
  extends Omit<RegisterDTO, "validate" | "toPlain" | "password" | "confirmPassword"> {
  password?: string;
  confirmPassword?: string;
}

export interface RegistrationError {
  error: number;
  statusCode: number;
  type: ErrorType;
  message: string;
  details: unknown;
}

export const clientAuthService = {
  login: async (data: Omit<LoginDTO, "validate" | "toPlain">) => {
    const response = await fetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error);
    }

    return result as DataReturnType<AuthResponseDTO & { refreshToken: string }>;
  },

  register: async (
    data: RegisterDTOWithOptionalPassword,
  ): Promise<DataReturnType<AuthResponseDTO & { refreshToken: string }> | RegistrationError> => {
    const response = await fetch("/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = (await response.json()) as
      | DataReturnType<AuthResponseDTO & { refreshToken: string }>
      | RegistrationError;

    return result;
  },
};
