"use client";

import { formatNumberWithCommas } from "@alba-cars/common-modules";
import clsx from "clsx";
import React from "react";
import { Controller, Control, Path } from "react-hook-form";

interface FormTextFieldProps<T extends Record<string, any>> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  capitalise?: boolean;
  required?: boolean;
  maxLength?: number;
  placeholder?: string;
  type?: "text" | "password" | "email" | "number";
  disabled?: boolean;
  formatNumber?: boolean;
  className?: string;
  [key: string]: any;
}

const formatInput = (value: string): string => {
  const numbersOnly = value.replace(/[^\d]/g, "");
  if (!numbersOnly) return "";
  return formatNumberWithCommas(numbersOnly);
};

export default function FormTextField<T extends Record<string, any>>({
  control,
  name,
  label,
  capitalise,
  required,
  maxLength = 255,
  placeholder,
  type = "text",
  disabled,
  formatNumber = false,
  className = "",
  ...props
}: FormTextFieldProps<T>) {
  const finalPlaceholder = required ? `${placeholder} *` : placeholder;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = "", onChange, ...fieldProps }, fieldState: { error } }) => (
        <div className="w-full">
          {label && (
            <label className="inter mb-3 block text-sm font-semibold text-[#000000]">
              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
            </label>
          )}
          <div className="relative">
            <input
              {...fieldProps}
              value={formatNumber && type === "number" ? formatInput(String(value)) : value}
              onChange={(e) => {
                let newValue = e.target.value;

                if (formatNumber && type === "number") {
                  // Store the raw number value without commas
                  newValue = e.target.value.replace(/,/g, "");
                  // Convert to number for form state
                  onChange(Number(newValue));
                } else if (capitalise) {
                  onChange(e.target.value.toUpperCase());
                } else if (type === "number") {
                  onChange(Number(e.target.value));
                } else {
                  onChange(e.target.value);
                }
              }}
              type={formatNumber ? "text" : type}
              placeholder={finalPlaceholder}
              maxLength={maxLength}
              disabled={disabled}
              className={clsx(
                "block w-full rounded-2xl bg-secondary-light",
                "min-h-[42px] px-6 py-4",
                "inter text-sm font-medium text-gray-800",
                "border border-secondary-light",
                error && "border-red-500",
                !error && "hover:border-grey-border",
                "focus:outline-none",
                error
                  ? "focus:border-red-500 focus:ring-1 focus:ring-red-500"
                  : "focus:border-grey-border focus:ring-1 focus:ring-grey-border",
                "transition-all duration-300",
                disabled && "cursor-not-allowed bg-gray-100 opacity-75",
                className,
              )}
              {...props}
            />
          </div>
          {error && <div className="mt-1 text-sm text-red-500">{error.message}</div>}
        </div>
      )}
    />
  );
}
