/* eslint-disable import/no-named-as-default */
"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { signIn, SignInResponse } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { CheckPhoneNumberResponse } from "@/app/api/auth/check-number/route";
import { AUTH_TYPES } from "@/auth";
import Button from "@/components/ui/buttons/Button";
import { clientAuthService } from "@/lib/api/auth/auth";
import { cn } from "@/lib/utils";
import { REGISTER_ERRORS } from "@/utils/constants";
import FormOtpField from "../common/forms/FormOtpField";
import FormPhoneField from "../common/forms/FormPhoneField";
import FormTextField from "../common/forms/FormTextField";
import { DialogTitle } from "../ui/dialog";
import { AuthFormValues, authSchema } from "./schemas";

interface AuthFormProps {
  favorites: Set<string>;
  closeDialog: () => void;
  clearFavorites: () => void;
  isPage?: boolean;
}

const formFieldVariants = {
  initial: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
  },
  animate: {
    height: "auto",
    opacity: 1,
    marginBottom: 16,
    transition: {
      height: {
        duration: 0.3,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
    transition: {
      height: {
        duration: 0.3,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
};

const AuthForm: React.FC<AuthFormProps> = ({ favorites, closeDialog, clearFavorites, isPage = false }) => {
  const [authType, setAuthType] = useState<"login" | "register">("login");
  const [formMessage, setFormMessage] = useState<{ message: string; type: "info" | "error" }>({
    message: "",
    type: "info",
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");

  const [loadingState, setLoadingState] = useState({
    isLoginLoading: false,
    isOtpLoading: false,
  });

  const router = useRouter();

  const authForm = useForm<AuthFormValues>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      authType: "login",
      phone: "",
      code: "",
      savedFavoriteVehicles: [],
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleSendOTP = async (phone: string) => {
    setLoadingState({ ...loadingState, isOtpLoading: true });

    try {
      const checkPhoneNumberResponse = await fetch("/api/auth/check-number", {
        method: "POST",
        body: JSON.stringify({ phone }),
      });

      const phoneNumberCheck = (await checkPhoneNumberResponse.json()) as CheckPhoneNumberResponse;

      if (!phoneNumberCheck.exists) {
        // Phone number does not exist, so we need to ask for the name and email (which will be optional)
        authForm.setValue("authType", "register");
        setAuthType("register");
        setFormMessage({
          message: "Please fill in the rest of your details to continue",
          type: "info",
        });
      }

      const response = await fetch("/api/auth/send-otp", {
        method: "POST",
        //* pass isRegistration to skip the check if the phone number exists (done already in the check-number route)
        body: JSON.stringify({ phone, isRegistration: true }),
      });

      const data = await response.json();

      if (data?.error) {
        setOtpError((_) => data?.details?.message ?? "Error while submitting OTP request");
        setTimeout(() => {
          setOtpError("");
        }, 2000);
        return;
      }

      setIsOtpSent(true);

      if (authForm.getValues("authType") === "login") {
        authForm.reset({
          authType: "login",
          phone: phone,
          code: "",
        });
      } else {
        authForm.reset({
          authType: "register",
          phone: phone,
          code: "",
          name: "",
          ...(data.email ? { email: data.email } : {}),
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setLoadingState({ ...loadingState, isOtpLoading: false });
    }
  };

  const onLoginSubmit = async (data: AuthFormValues) => {
    setLoadingState({ ...loadingState, isLoginLoading: true });

    try {
      if (favorites.size > 0) {
        data.savedFavoriteVehicles = Array.from(favorites);
      }

      if (authType === "register" && data.authType === "register") {
        const registerResponse = await clientAuthService.register({
          ...data,
          email: data.email ?? undefined,
        });

        if (registerResponse.error === REGISTER_ERRORS.USER_ALREADY_EXISTS) {
          setFormMessage({
            message: "This email is already in use.",
            type: "error",
          });
          return;
        }
      }

      let response: SignInResponse | undefined;

      response = await signIn(AUTH_TYPES.OTP, {
        ...data,
        redirect: true,
        redirectTo: "/my-profile",
      });

      if (response?.error) {
        console.error("Login error:", response.error);
        throw Error(response.error);
      }

      if (response?.ok) {
        closeDialog();
        clearFavorites();
        router.push("/my-profile");
      }
    } catch (error: any) {
      setFormMessage({
        message: "Unable to verify your phone number.",
        type: "error",
      });
      console.log(error);
    } finally {
      setLoadingState({ ...loadingState, isLoginLoading: false });
    }
  };

  return (
    <>
      {isPage ? (
        <>
          <h1 className="urbanist text-center text-3xl font-semibold">
            <span>Access our client portal</span>
          </h1>
          <span className="inter text-primary-foreground">Login or register as a new user</span>
        </>
      ) : (
        <div className="text-center">
          <DialogTitle className="urbanist text-center text-3xl font-semibold">
            <span>Access our client portal</span>
          </DialogTitle>
          <span className="inter text-primary-foreground">Login or register as a new user</span>
        </div>
      )}
      {formMessage ? (
        <div className="flex items-center justify-center">
          <span
            className={`inter text-sm ${formMessage.type === "error" ? "text-red-500" : "text-secondary-foreground"}`}
          >
            {formMessage.message}
          </span>
        </div>
      ) : null}

      <form onSubmit={authForm.handleSubmit(onLoginSubmit)} className="space-y-4">
        <div className="flex flex-col">
          <div className={cn("mx-auto w-full space-y-4", !isPage ? "md:max-w-[80%]" : "")}>
            <FormPhoneField
              control={authForm.control}
              name="phone"
              placeholder="Phone Number"
              disabled={isOtpSent}
              required
            />

            <AnimatePresence mode="sync">
              {isOtpSent && authType === "register" && (
                <motion.div variants={formFieldVariants} initial="initial" animate="animate" exit="exit">
                  <div className="space-y-4">
                    <FormTextField
                      control={authForm.control}
                      name="name"
                      placeholder="Your name"
                      type="text"
                      required
                    />
                    <FormTextField
                      control={authForm.control}
                      name="email"
                      placeholder="Your email (Optional)"
                      type="email"
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence mode="sync">
              {isOtpSent && (
                <motion.div variants={formFieldVariants} initial="initial" animate="animate" exit="exit">
                  <FormOtpField control={authForm.control} name="code" length={6} label="OTP" required />
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              buttonText={isOtpSent ? "Login" : "Send OTP"}
              className="mt-10 w-full rounded-lg bg-primary py-3 text-center text-white transition-all duration-300 hover:bg-primary-dark disabled:cursor-not-allowed disabled:opacity-50"
              loadingState={isOtpSent ? loadingState.isLoginLoading : loadingState.isOtpLoading}
              onClick={
                isOtpSent ? authForm.handleSubmit(onLoginSubmit) : () => handleSendOTP(authForm.getValues("phone"))
              }
            />
          </div>
          {otpError ? <span className="inter mt-6 text-center text-primary">{otpError}</span> : null}
        </div>
      </form>
    </>
  );
};

export default AuthForm;
