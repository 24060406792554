import { ClipboardEvent, KeyboardEvent, useRef } from "react";
import { Control, Controller, Path } from "react-hook-form";

import { cn } from "@/lib/utils";

interface FormOtpFieldProps<T extends Record<string, any>> {
  name: Path<T>;
  control: Control<T>;
  length?: number;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

const FormOtpField = <T extends Record<string, any>>({
  name,
  control,
  length = 6,
  label,
  required,
  disabled,
}: FormOtpFieldProps<T>) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // this function handles the backspace key event (move backwards in the input field)
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      if ((e.target as HTMLInputElement).value === "" && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleInput = (index: number, value: string, onChange: (value: string) => void) => {
    // Only allow numbers
    const sanitizedValue = value.replace(/[^0-9]/g, "");

    const currentInput = inputRefs.current[index];
    if (currentInput) {
      currentInput.value = sanitizedValue.slice(-1);
    }

    // Combine all the values
    const otpValue = inputRefs.current.map((input) => input?.value || "").join("");

    onChange(otpValue);

    // Move to the next input if there are more inputs
    if (sanitizedValue && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    e.preventDefault();
    // get the value from the clipboard and sanitize
    const pastedData = e.clipboardData.getData("text/plain").replace(/[^0-9]/g, "");

    for (let i = 0; i < Math.min(length, pastedData.length); i++) {
      const input = inputRefs.current[i];
      if (input) {
        input.value = pastedData[i];
      }
    }

    onChange(pastedData.slice(0, length));

    // Focus last filled input
    const focusIndex = Math.min(length - 1, pastedData.length - 1);
    if (focusIndex >= 0) {
      inputRefs.current[focusIndex]?.focus();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? "This field is required" : false }}
      render={({ field: { onChange, value = "", ...field }, fieldState: { error } }) => {
        // Split value into array for individual inputs
        // this is to prevent the input from overflowing over the size of the input field
        const valueArray = value.split("").concat(Array(length).fill("")).slice(0, length);

        return (
          <div className="flex flex-col items-start">
            {label && (
              <label className="inter mb-2 block text-sm font-semibold text-[#000000]">
                {label}
                {required && <span className="ml-1 text-red-500">*</span>}
              </label>
            )}
            <div className="flex w-full gap-1 self-center md:gap-4 md:self-start">
              {Array.from({ length }).map((_, index) => (
                <input
                  key={index}
                  {...field}
                  ref={(el) => {
                    if (el) {
                      inputRefs.current[index] = el;
                    }
                  }}
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  value={valueArray[index]}
                  disabled={disabled}
                  className={cn(
                    "h-12 w-12 flex-grow rounded-xl border text-center text-2xl",
                    "inter font-medium text-gray-800",
                    "border-secondary-light bg-secondary-light",
                    "transition-all duration-300",
                    "hover:border-grey-border",
                    "focus:border-grey-border focus:outline-none focus:ring-1 focus:ring-grey-border",
                    error ? "border-red-500 focus:border-red-500 focus:ring-red-500" : "",
                    disabled && "cursor-not-allowed bg-gray-100 opacity-75",
                  )}
                  onChange={(e) => handleInput(index, e.target.value, onChange)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={(e) => handlePaste(e, onChange)}
                />
              ))}
            </div>
            {error && <p className="inter mt-1 text-center text-sm text-red-500">{error.message}</p>}
          </div>
        );
      }}
    />
  );
};

export default FormOtpField;
