import { DataReturnType, ReturnTypeWithPagination, Vehicle, apiRequest } from "@alba-cars/common-modules";

interface GetUserFavoriteVehiclesInput {
  userId: string;
  token: string;
}

export const getUserFavorites = async (input: GetUserFavoriteVehiclesInput) => {
  try {
    const { userId, token } = input;
    const endpoint = `/favorites/user/${userId}`;
    const response = await apiRequest<DataReturnType<Vehicle[]>>(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      next: {
        tags: ["user-favorites"],
      },
    });
    return response;
  } catch (error) {
    console.error("[GET_USER_FAVORITES_ERROR]", error);
    return null;
  }
};

export const getAuthenticatedUserFavorites = async (
  { token, page }: { token: string; page?: number } = { token: "", page: 1 },
) => {
  const endpoint = `/favorites/my?page=${page}`;
  return apiRequest<ReturnTypeWithPagination<Vehicle[]>>(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    next: {
      tags: ["auth-user-favorites"],
    },
  });
};

export const getAuthenticatedUserFavoriteIds = async (accessToken: string) => {
  if (!accessToken) {
    return null;
  }

  const endpoint = `/favorites/my/ids`;
  return apiRequest<DataReturnType<string[]>>(endpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    next: {
      tags: ["auth-user-favorites-ids"],
    },
  });
};
