"use client";

import clsx from "clsx";
import {
  getCountries,
  getCountryCallingCode,
  AsYouType,
  CountryCode,
  parsePhoneNumberWithError,
} from "libphonenumber-js";
import { ChevronDown, Search } from "lucide-react";
import React, { useState, useMemo, useRef, useEffect } from "react";
import { Controller, Control, Path } from "react-hook-form";

import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";

export const isValidPhoneNumber = (value: string) => {
  if (!value) {
    return false;
  }

  try {
    const phoneNumber = parsePhoneNumberWithError(value);
    const isValid = phoneNumber?.isValid();

    return isValid;
  } catch (error) {
    return false;
  }
};

// Get all countries data
const allCountries = getCountries().map((country) => ({
  code: country,
  dialCode: `+${getCountryCallingCode(country as CountryCode)}`,
  name: new Intl.DisplayNames(["en"], { type: "region" }).of(country) || country,
}));

interface FormPhoneFieldProps<T extends Record<string, any>> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onCountryChange?: (country: string) => void;
  [key: string]: any;
}

export default function FormPhoneField<T extends Record<string, any>>({
  control,
  name,
  label,
  required,
  placeholder,
  disabled,
  onCountryChange,
  className = "",
  ...props
}: FormPhoneFieldProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(allCountries.find((e) => e.code === "AE")!);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is outside both the container and dropdown
      const isOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target as Node);

      if (isOpen && isOutsideDropdown) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Filter countries based on search
  const filteredCountries = useMemo(() => {
    const searchLower = search.toLowerCase();
    return allCountries.filter(
      (country) =>
        country.name.toLowerCase().includes(searchLower) ||
        country.dialCode.includes(searchLower) ||
        country.code.toLowerCase().includes(searchLower),
    );
  }, [search]);

  // Format and validate phone number
  const formatPhoneNumber = (value: string, country: string) => {
    const formatter = new AsYouType(country as CountryCode);
    return formatter.input(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = "", onChange, onBlur, ...fieldProps }, fieldState: { error } }) => (
        <div className="w-full">
          {label && (
            <label className="inter mb-1 block text-sm font-semibold text-[#000000]">
              {label}
              {required && <span className="ml-1 text-primary">*</span>}
            </label>
          )}
          <div className="relative">
            {/* Wrapper for combined border effect */}
            <div
              className={clsx(
                "relative flex rounded-2xl",
                "border transition-all duration-300",
                error
                  ? "border-primary ring-1 ring-primary"
                  : "border-secondary-light focus-within:border-grey-border focus-within:ring-1 focus-within:ring-grey-border hover:border-grey-border",
              )}
            >
              {/* Country selector */}
              <button
                type="button"
                onClick={() => !disabled && setIsOpen(!isOpen)}
                className={clsx(
                  "flex items-center gap-2 rounded-l-2xl bg-secondary-light px-3",
                  "inter min-h-[42px] text-sm font-medium text-gray-800",
                  disabled && "cursor-not-allowed bg-gray-100 opacity-75",
                )}
              >
                <img
                  src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                  alt={selectedCountry.name}
                  className="h-4 w-6 object-contain"
                />
                <span>{selectedCountry.dialCode}</span>
                <ChevronDown className="h-4 w-4" />
              </button>

              {/* Phone number input */}
              <input
                {...fieldProps}
                value={value ? value.replace(selectedCountry.dialCode, "") : ""}
                onChange={(e) => {
                  let formatted = formatPhoneNumber(e.target.value, selectedCountry.code);
                  if (formatted.startsWith("0")) {
                    formatted = formatted.slice(1);
                  }

                  const fullNumber = formatted ? `${selectedCountry.dialCode}${formatted.replace(/\D/g, "")}` : "";
                  onChange(fullNumber);
                }}
                onBlur={onBlur} // Add onBlur handler
                type="tel"
                placeholder={required ? `${placeholder} *` : placeholder}
                disabled={disabled}
                className={clsx(
                  "block w-full rounded-r-2xl bg-secondary-light",
                  "min-h-[42px] px-6 py-4",
                  "inter text-sm font-medium text-gray-800",
                  "border-0 focus:outline-none",
                  disabled && "cursor-not-allowed bg-gray-100 opacity-75",
                  className,
                )}
                {...props}
              />

              {/* Country dropdown */}
              {isOpen && (
                <Card ref={dropdownRef} className="absolute left-0 top-full z-50 mt-1 w-72 bg-white">
                  <div className="p-2">
                    <div className="relative">
                      <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
                      <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search countries..."
                        className="pl-8"
                      />
                    </div>
                  </div>
                  <div className="max-h-60 overflow-auto">
                    {filteredCountries.map((country) => (
                      <button
                        key={country.code}
                        type="button"
                        onClick={() => {
                          setSelectedCountry(country);
                          setIsOpen(false);
                          // Update the full number with new country code
                          const currentNumber = value.replace(/\D/g, "").replace(/^[0-9]+/, "");
                          const newFullNumber = currentNumber ? `${country.dialCode}${currentNumber}` : "";
                          onChange(newFullNumber);
                          onCountryChange?.(country.code);
                        }}
                        className={clsx(
                          "flex w-full items-center gap-3 px-4 py-2",
                          "hover:bg-gray-50",
                          "inter text-sm text-gray-800",
                        )}
                      >
                        <img
                          src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                          alt={country.name}
                          className="h-4 w-6 object-contain"
                        />
                        <span>{country.name}</span>
                        <span className="ml-auto text-gray-500">{country.dialCode}</span>
                      </button>
                    ))}
                  </div>
                </Card>
              )}
            </div>
          </div>
          {error && <div className="mt-1 text-sm text-primary">{error.message}</div>}
        </div>
      )}
    />
  );
}
